<template>
  <div class="hd flex col-top row-between">
    <div class="back flex f20 cup" @click="goBack" v-if="type == 'back'">
      <img src="@/assets/img/back.png" />
      <span>后退</span>
    </div>
    <div class="search flex" v-if="type == 'search'" style="margin-top: 15px">
      <input
        type="text"
        :placeholder="
          mtype == 'activity' ? '请输入活动名称搜索' : '请输入课程名称搜索'
        "
        class="searchInput"
        :value="value"
        @input="onInput"
        @keydown.enter="onsearch"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div class="searchIcon" @click="onsearch">
        <img src="../assets/img/search.png" />
      </div>
      <div class="del" v-if="showDel" @click="onClear">
        <img src="../assets/img/img28.png" />
      </div>
    </div>

    <user bg-color="#F1F1F1" style="margin-right: 23px;"></user>
  </div>
</template>

<script>
import User from './user.vue'
export default {
  name: 'myHeader',
  components: {
    User
  },
  props: {
    diyRouter: {
      type: String
    },
    type: {
      type: String,
      default: 'back'
    },
    value: [Number, String],
    mtype: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDel: false
    }
  },
  watch: {
    value(oldVal) {
      if (oldVal) {
        this.showDel = true
      } else {
        this.showDel = false
      }
    }
  },
  methods: {
    onsearch() {
      this.$emit('search')
    },
    onInput(event) {
      let value = event.currentTarget.value
      value = this.trim(value)
      this.$emit('input', value)
    },
    goBack() {
      if (this.diyRouter) {
        this.$router.replace({
          name: this.diyRouter
        })
        return
      }
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    // 去除空格
    trim(str) {
      // eslint-disable-next-line
      return str.replace(/^\s+|\s+$/g, '')
    },
    // 有焦点事件
    onFocus() {
      this.$emit('focus')
    },
    // 失去焦点事件
    onBlur() {
      this.$emit('blur')
    },
    onClear(event) {
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.hd {
  .search {
    overflow: hidden;
    width: 360px;
    height: 40px;
    background-color: #ecf1ff;
    border-radius: 6px;
    position: relative;
    .del {
      position: absolute;
      top: 50%;
      right: 86px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      cursor: pointer;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .searchIcon {
      position: relative;
      width: 67px;
      background-color: #d9e2fc;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      height: 50px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
      }
    }

    .searchInput {
      background-color: inherit;
      border: 0;
      flex: 1;
      padding: 0 15px;
      font-size: 12px;
      color: #000034;

      &:-moz-placeholder {
        color: #95c2ea;
      }

      &::-moz-placeholder {
        color: #95c2ea;
      }

      &::-webkit-input-placeholder {
        color: #95c2ea;
      }

      &:-ms-input-placeholder {
        color: #95c2ea;
      }
    }
  }

  .back {
    color: #6672fb;

    span {
      font-weight: bold;
    }

    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
</style>
